import Glide, { Controls, Images, Swipe, Keyboard } from '@glidejs/glide/dist/glide.modular.esm';
import { getDir } from '../../../common/components/utils/utils';

const SELECTORS = {
	root: '#clinic-gallery',
	slides: '.glide__slides > *',
};

const ATTRIBUTES = {
	modalGallery: 'data-modal-gallery',
	itemIndex: 'data-modal-gallery-index',
};

class ClinicGallery {
	constructor() {
		this.rootEl = document.querySelector(SELECTORS.root);

		if ([this.rootEl].some((el) => !el)) {
			return;
		}

		this.bindEvents();
		this.setup();
	}

	/**
	 * Bind `this` explicitly for event listeners.
	 */
	bindEvents() {
		['onTransition'].forEach((e) => {
			this[e] = this[e].bind(this);
		});
	}

	/**
	 * When slider moves update the counter element.
	 */
	onTransition() {
		if (!this.counterEl) return;

		const { index } = this.slider;

		this.counterEl.textContent = `${index + 1}/${this.numberOfElements}`;
	}

	setupSlider() {
		this.slider = new Glide(this.rootEl, {
			type: 'carousel',
			perView: 1,
			gap: 32,
			direction: getDir(),
		}).mount({ Controls, Keyboard, Swipe, Images });

		this.slider.on('run', this.onTransition);
	}

	/**
	 * When the modal is open update the slider and set the current index to the data attribute.
	 *
	 * [1]: We need to update the slider because if the modal is closed and the viewport is resized it can't
	 * 	recalculate the width.
	 *
	 * @param {HTMLElement} trigger Modal trigger (image), used for extracting data attributes.
	 */
	updateSlider(trigger) {
		let index = Number(trigger.getAttribute(ATTRIBUTES.itemIndex));

		if (!index || index >= this.numberOfElements) index = 0;

		this.counterEl.textContent = `${index + 1}/${this.numberOfElements}`;
		this.slider.update({ startAt: index }); // [1]
	}

	setup() {
		this.numberOfElements = this.rootEl.querySelectorAll(SELECTORS.slides).length;
		const counterSelector = this.rootEl.dataset.sliderCounter;
		if (counterSelector) {
			this.counterEl = document.getElementById(counterSelector);
		}

		this.setupSlider();
	}
}

/**
 * Singleton is used to create one instance of ClinicGallery.
 * Because when the slider is initialized it will create duplicate elements, and we can't get the actual count.
 * Also, we don't want to reinitialize the slider on each modal open.
 *
 * We can't initialize the slider on page load as the modal is hidden and width can't be calculated.
 * To mitigate this we initialize the slider when the modal opens.
 *
 *
 */
export const ClinicGallerySlider = (() => {
	let instance;

	function createInstance() {
		return new ClinicGallery();
	}

	return {
		/**
		 * Check if the passed modal is the gallery modal type.
		 * @param {HTMLElement} modal
		 * @returns {boolean | undefined}
		 */
		isGalleryType(modal) {
			return modal?.hasAttribute(ATTRIBUTES.modalGallery);
		},
		/**
		 * Callback for when the modal is opened.
		 */
		onModalOpen(modal, trigger) {
			if (!this.isGalleryType(modal)) return;
			if (!instance) {
				instance = createInstance();
			}

			instance.updateSlider(trigger);
		},
	};
})();
