<template>
	<div class="text-editor">
		<input type="hidden" :name="name" :value="description" />
		<editor-content :editor="editor" />
		<div class="rte-menu-bar" v-if="editor">
			<button
				class="rte-menu-bar--btn"
				:class="{ active: editor.isActive('bold') }"
				type="button"
				@click="this.editor.chain().focus().toggleBold().run()"
			>
				<svg class="icon" role="img">
					<use xlink:href="#icon-bold"></use>
				</svg>
			</button>
			<button
				class="rte-menu-bar--btn"
				:class="{ active: editor.isActive('italic') }"
				type="button"
				@click="this.editor.chain().focus().toggleItalic().run()"
			>
				<svg class="icon" role="img">
					<use xlink:href="#icon-italic"></use>
				</svg>
			</button>
			<button
				class="rte-menu-bar--btn"
				:class="{ active: editor.isActive('underline') }"
				type="button"
				@click="this.editor.chain().focus().toggleUnderline().run()"
			>
				<svg class="icon" role="img">
					<use xlink:href="#icon-underline"></use>
				</svg>
			</button>
			<div class="rte-menu-bar--extended" v-if="extend">
				<button
					class="rte-menu-bar--btn"
					:class="{ active: editor.isActive('heading') }"
					type="button"
					@click="this.editor.chain().focus().toggleHeading({ level: 4 }).run()"
				>
					<svg class="icon" role="img">
						<use xlink:href="#icon-heading"></use>
					</svg>
				</button>

				<button
					v-for="({ slug, option, active, icon }, index) in textActions"
					class="rte-menu-bar--btn"
					:class="{ active: editor.isActive(active) }"
					type="button"
					@click="onActionClick(slug, option)"
				>
					<svg class="icon" role="img">
						<use :xlink:href="`#icon-${icon}`"></use>
					</svg>
				</button>
			</div>
		</div>
	</div>
</template>

<script>
import { Editor, EditorContent } from '@tiptap/vue-3';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import 'remixicon/fonts/remixicon.css';

export default {
	components: {
		EditorContent,
	},
	props: {
		content: {
			type: String,
			required: false,
		},
		/**
		 * Used for hidden input, so
		 * the form will pick up the
		 * editor content
		 */
		name: {
			type: String,
			required: false,
		},
		errorLinkMsg: {
			type: String,
			required: false,
			default: 'Please enter the correct URL. It should start with either http:// or https://',
		},
		extend: {
			type: Boolean,
			required: false,
			default: true,
		},
	},
	data() {
		return {
			editor: null,
			description: this.content,
			textActions: [
				{
					slug: 'bulletList',
					icon: 'bullet-list',
					active: 'bulletList',
				},
				{
					slug: 'link',
					icon: 'link',
					active: 'link',
				},
			],
		};
	},
	methods: {
		setLink() {
			const previousUrl = this.editor.getAttributes('link').href;
			const url = window.prompt('URL', previousUrl);
			const regex = '^(http|https)://';

			if (!url.match(regex)) {
				alert(this.errorLinkMsg);
				return;
			}

			// cancelled
			if (url === null) {
				return;
			}

			// empty
			if (url === '') {
				this.editor.chain().focus().extendMarkRange('link').unsetLink().run();

				return;
			}

			// update link
			this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
		},
		onActionClick(slug, option = null) {
			const vm = this.editor.chain().focus();
			const actionTriggers = {
				bulletList: () => vm.toggleBulletList().run(),
				link: () => this.setLink(),
			};

			actionTriggers[slug]();
		},
		onHeadingClick(index) {
			const vm = this.editor.chain().focus();
			vm.toggleHeading({ level: index }).run();
		},
	},
	mounted() {
		this.editor = new Editor({
			content: this.content,
			extensions: [StarterKit, Underline, Link],
			onUpdate: ({ editor }) => {
				this.description = editor.getHTML();
			},
		});
	},
	beforeUnmount() {
		this.editor.destroy();
	},
};
</script>
