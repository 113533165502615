<script>
import { onMounted, ref, watch } from 'vue';
import { CalendarCustom } from '../CalendarCustom';
import { DATE_FORMAT_API } from '../utils/constants';
import { ShiftModel } from '../models/Shift.model';
import { useBookingFlowStore } from '../offline-booking/store';
import { dateGetMonthRange } from '../utils/dateGetMonthRange';
import dayjs from 'dayjs';
import { clone } from '../utils/clone';

/**
 *
 * @version 1.0.0
 * @since
 */
export default {
	name: 'VTreatmentDaysAndShifts',
	components: {},
	props: {
		shifts: {
			required: true,
			type: Object,
			default: () => ({}),
		},
		dialysisType: {
			required: true,
			type: String,
		},
		keyProperty: {
			required: false,
			type: String,
			default: 'id',
		},
		labelProperty: {
			required: false,
			type: String,
			default: 'name',
		},
		options: {
			required: true,
			type: Object,
			default: () => [],
		},
		selectedShifts: {
			required: false,
			type: Number,
			default: 0,
		},
		lang: {
            required: false,
			type: String,
            default: 'en',
		}
	},
	setup(props) {
		/*************************************************
		 * HTML Refs
		 **************************************************/
		const dateInputElement = ref(null);
		const minTreatmentDays = ref(false);

		/*************************************************
		 * State
		 **************************************************/
		const store = useBookingFlowStore();
		const shifts = ref(formatShifts({ shifts: props.shifts }));
		const userFormat = ref(DATE_FORMAT_API);
		const slotsState = ref([]);

		/*************************************************
		 * Init
		 **************************************************/
		let calendar;
		onMounted(() => {
			calendar = new CalendarCustom(dateInputElement.value, {
				plugins: ['multiselect'],
				singleMode: true,
				numberOfColumns: 1,
				numberOfMonths: 1,
				dontAppendFromToInputs: true,
				lang: props.lang,
				setup: (instance) => {
					instance.on('before:show', async () => {
						const slots = await getSlotsShifts({ shifts: shifts.value });
						setSlots({ slots });

						const [firstShift] = shifts.value;
						instance.gotoDate(firstShift?.date.toDate());

						await updateDisabledDates({ instance });
					});
					instance.on('change:month', async () => {
						await updateDisabledDates({ instance });
					});
					instance.on('multiselect.select', (date) => {
						addShift(date.dateInstance);
					});
					instance.on('multiselect.deselect', (date) => {
						removeShift(date.dateInstance);
					});
				},
			});

			calendar.instance.selectMultipleDates(shifts.value.map(({ date }) => date));
            updateTreatmentDays(shifts.value);
		});

		/*************************************************
		 * Methods
		 **************************************************/
		function formatShiftLabel(date) {
			const options = {
				weekday: "long",
				month: "long",
				day: "numeric",
			};
			let translateDate = new Date(date).toLocaleDateString(props.lang, options);

			return translateDate;
		}

		function formatShifts({ shifts }) {
			return Object.entries(shifts).map(([date, obj]) => {
				return new ShiftModel({ date, ...obj });
			});
		}

		function addShift(date) {
			const options = getOptions({
				slots: slotsState.value,
				shiftDate: date,
				options: props.options,
				dialysisType: props.dialysisType,
				keyProperty: props.keyProperty,
				labelProperty: props.labelProperty,
			});
			const [selected] = options;
			const shift = new ShiftModel({
				date,
				options,
				selected: selected[props.keyProperty],
			});
			shifts.value = [...shifts.value, shift];
			shifts.value.sort((a, b) => a.date.valueOf() - b.date.valueOf());
			return shift;
		}

		function removeShift(date) {
			shifts.value = shifts.value.filter((s) => s.date.valueOf() !== date.valueOf());
		}

		function unselectShift(shift) {
			removeShift(shift.date);
			calendar.instance.unselectDate(shift.date, { triggerDeselectEvent: false });
		}

		function formatSelectedShifts(shifts) {
			return shifts.reduce(
				(obj, shift) => ({
					...obj,
					[shift.dateAPI]: shift.selected,
				}),
				{}
			);
		}

		function onChangeShift(shift, event) {
			const sIndex = shifts.value.findIndex((s) => s.dateAPI === shift.dateAPI);
			shifts.value[sIndex] = new ShiftModel({
				...shift,
				selected: event.target.value,
			});
			shifts.value = clone(shifts.value).map((s) => new ShiftModel({ ...s }));
		}

		function updateTreatmentDays(shifts) {
			store.treatmentDays = shifts.length;
			// in order to save a new schedule, there should be the same amount of shifts as preselected
			minTreatmentDays.value = props.selectedShifts > store.treatmentDays;
		}

		// in order to connect 2 Vue components and calc price in booking flow
		// we have a function to write price value in store and use it in other component (v-booking-total)
		function updatePrice(shifts) {
			// if other component is not available on the page, prevent error
			if(!window.priceUpdateURL) return;
			store.isLoading = true;
			const apiData = {
				days: formatSelectedShifts(shifts),
			};

			http.post(window.priceUpdateURL, apiData)
				.then(({ data }) => (store.price = data.totalPrice || ''))
				.finally(() => (store.isLoading = false));
		}

		async function getSlotsAPI({ from, to }) {
			const {
				data: {
					data: { slots },
				},
			} = await window.http.get(window.clinicAvailabilityURL, {
				params: {
					from,
					to,
				},
			});

			return slots;
		}

		function setDisabledDates({ instance, slots }) {
			const disableDates = slots
				.filter((status) => !status.overall[props.dialysisType])
				.map((status) => status.date);

			instance.setLockDays(disableDates);
		}

		function getCalendarRange({ instance }) {
			const [date] = instance.calendars;
			return dateGetMonthRange({
				date: date.dateInstance,
				format: DATE_FORMAT_API,
			});
		}

		function setSlots({ slots }) {
			const newSlots = slots.map(JSON.stringify);
			const oldSlots = slotsState.value.map(JSON.stringify);
			slotsState.value = [...new Set([...oldSlots, ...newSlots])].map(JSON.parse);
		}

		function getOptions({
			slots,
			shiftDate,
			options,
			dialysisType,
			keyProperty,
			labelProperty,
		}) {
			const slot = slots.find(({ date }) => dayjs(date).isSame(dayjs(shiftDate)));

			if (!slot) {
				return [];
			}

			return Object.entries(options)
				.filter(([slotId]) => slot[slotId][dialysisType])
				.map(([slotId, slotLabel]) => {
					return {
						[keyProperty]: slotId,
						[labelProperty]: slotLabel,
					};
				});
		}

		async function updateDisabledDates({ instance }) {
			const { from, to } = getCalendarRange({ instance });
			const slots = await getSlotsAPI({ from, to });
			setDisabledDates({ instance, slots });
			setSlots({ slots });
		}

		/**
		 * Checks if there are `slots` for all `shifts`
		 * And if there is not it gets them.
		 *
		 * @param shifts
		 * @returns {Promise<void>}
		 */
		async function getSlotsShifts({ shifts }) {
			const shiftsDates = shifts.map((shift) => shift.dateAPI);
			const [from] = shiftsDates;
			const to = shiftsDates.pop();

			const alreadyInSlots = [from, to].every(
				(date) => !!slotsState.value.find((slot) => slot.date === date)
			);

			if (alreadyInSlots) {
				return Promise.resolve([]);
			}

			return await getSlotsAPI({ from, to });
		}

		watch(
			() => shifts.value,
			(newShifts) => {
                updateTreatmentDays(newShifts);
				updatePrice(newShifts);
			}
		);

		watch(
			() => slotsState.value,
			(newSlots) => {
				shifts.value.forEach((shift) => {
					shift.options = getOptions({
						slots: newSlots,
						shiftDate: shift.date,
						options: props.options,
						dialysisType: props.dialysisType,
						keyProperty: props.keyProperty,
						labelProperty: props.labelProperty,
					});
				});
			}
		);

		return {
			// Refs
			dateInputElement,
			minTreatmentDays,

			// State
			shifts,
			userFormat,

			// Methods
			formatShiftLabel,
			unselectShift,
			onChangeShift,
		};
	},
};
</script>

<template>
	<!-- Calendar -->
	<div class="v-treatment-das__calendar litepicker--inline">
		<input
			ref="dateInputElement"
			type="hidden"
			disabled
			name="period"
			autocomplete="off"
			v-bind="$attrs"
		/>
	</div>

	<slot v-if="minTreatmentDays" name="minimal-treatments"></slot>

	<!-- Shifts -->
	<div class="v-treatment-das__shifts">
		<div
			class="flex-center"
			v-for="shift in shifts"
			:key="JSON.stringify(shift)"
		>
			<div class="form-group width-100" :class="{ 'form-group--error': !!shift.error }">
				<label class="form-label" for="shift-component">
					{{ formatShiftLabel(shift.date) }}
				</label>

				<div class="flex-row flex-center mt-8">
					<div class="form-group__select-wrapper v-treatment-das__shifts-select m-0">
						<select
							ref="selectElement"
							class="form-group__select"
							id="shift-component"
							:name="`days[${shift.dateAPI}]`"
							@change="onChangeShift(shift, $event)"
						>
							<option
								v-for="option in shift.options"
								:key="option[keyProperty]"
								:selected="shift.selected === option[keyProperty]"
								:value="option[keyProperty]"
							>
								{{ option[labelProperty] }}
							</option>
						</select>
					</div>

					<button
						class="v-treatment-das__close-button button button--reset"
						@click="unselectShift(shift)"
						type="button"
					>
						<svg class="icon reset-button-color" role="img">
							<use xlink:href="#icon-close"></use>
						</svg>
					</button>
				</div>

				<span v-if="shift.error" class="input-error">
					{{ shift.error }}
				</span>
			</div>
		</div>
	</div>
</template>
