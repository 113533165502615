import { isAttributeSafe } from './utils';
import { TEMPLATE_ATTRIBUTE, isTemplateAttribute, toDataSelector } from './templateEngineUtils';
import { DATA_TEXT_PROPERTY } from './constants';

/**
 * A mini templating engine for modal.
 *
 * When a modal opens it will check if the trigger has `TEMPLATE_ATTRIBUTE` attribute.
 * If the attribute is present it will extract all attributes with that prefix and
 * inside the modal find the element with same attributes and will the content.
 *
 * It can also accept and object (valid JSON), if an object is passed it will set an attribute.
 * The attributes need to be on the safe list (`isAttributeSafe`).
 *
 * @example
 *
 * <button data-micromodal-trigger="modal-1" data-template data-template-date="Wednesday, November 24">Trigger</button>
 *
 * <div class="modal" id="modal-1">
 *   <span data-template-date></span>
 * </div>
 *
 * @see {isAttributeSafe}
 */

export function onShow(modal, trigger, callback) {
	// Call the callback function if passed.
	callback?.();
	// Early check if there is a base attribute, if not exit.
	if (trigger.getAttribute(TEMPLATE_ATTRIBUTE) === null) return;

	const data = [];
	// Extract all dataset attributes
	const triggerDataset = { ...trigger.dataset };

	// Get all the attributes that match the template
	Object.keys(triggerDataset).forEach((key) => {
		const value = triggerDataset[key];
		if (isTemplateAttribute(key)) data.push({ key, value });
	});

	data.forEach(({ key, value }) => {
		const selector = toDataSelector(key);
		let v;

		try {
			v = JSON.parse(value);
		} catch (e) {
			v = value;
		}

		// Fill in all the matches
		Array.from(modal.querySelectorAll(selector) || []).forEach((elem) => {
			// For simple attributes add as text.
			// eslint-disable-next-line no-param-reassign
			if (typeof v === 'string') elem.textContent = value;
			// For objects add as attribute
			if (typeof v === 'object') {
				Object.keys(v).forEach((attr) => {
					if (isAttributeSafe(attr)) {
						if (attr === DATA_TEXT_PROPERTY) {
							// eslint-disable-next-line no-param-reassign
							elem.textContent = v[attr];
						} else {
							elem.setAttribute(attr, v[attr]);
						}
					}
				});
			}
		});
	});
}
