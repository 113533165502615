const inputArray = document.querySelectorAll('.enable-submit');
let enable = false;

/* eslint no-restricted-syntax: "off" */
function enableSubmitButton() {
	for (const element of inputArray) {
		enable = element.value !== '';
	}

	document.getElementById('submit-disabled').disabled = enable === false;
}

for (const element of inputArray) {
	element.addEventListener('keyup', enableSubmitButton);
}
