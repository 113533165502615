/**
 * Only allow some attributes to be added from the data array.
 * Premature XSS protection.
 *
 * @param {string} attribute
 * @return {boolean}
 */
import { DATA_TEXT_PROPERTY } from './constants';
import { hasTemplateAttributePrefix } from './templateEngineUtils';

export function isAttributeSafe(attribute) {
	// Dynamically resolve all modal template engine attributes.
	if (hasTemplateAttributePrefix(attribute)) return true;
	return [
		'class',
		'id',
		'href',
		'target',
		'action',
		'min',
		'max',
		'value',
		'data-template-hd',
		'data-template-hdf',
		'data-template-action',
		'data-template-date',
		'data-template-date-value',
		'data-template-shift',
		'data-template-booking-id',
		DATA_TEXT_PROPERTY,
	].includes(attribute);
}

export function debounce(fn, wait) {
	let t;
	return function deb() {
		clearTimeout(t);
		// eslint-disable-next-line prefer-rest-params
		t = setTimeout(() => fn.apply(this, arguments), wait);
	};
}

/**
 * Return a true typeof in JavaScript based on constructor name.
 * @param value
 * @return {string}
 */
function typeOf(value) {
	return Object.prototype.toString.call(value).split(' ')[1].slice(0, -1).toLowerCase();
}

export function isArray(value) {
	return typeOf(value) === 'array';
}

export function isString(value) {
	return typeOf(value) === 'string';
}

export function isBoolean(value) {
	return typeof value === 'boolean';
}

export function isFunction(value) {
	return typeOf(value) === 'function';
}

export function isObject(value) {
	return typeOf(value) === 'object';
}

export function isArrayOf(array, type) {
	return isArray(array) ? array.every((a) => typeOf(a) === type) : false;
}

/**
 * Recursively merges own and inherited enumerable string keyed
 * properties of source objects into the destination object.
 * Array and plain object properties are merged recursively.
 * This will mutate target object.
 *
 * @param {Object} target
 * @param {...Object} sources
 */
export function mergeDeep(target, ...sources) {
	if (!sources.length) return target;
	const source = sources.shift();

	if (isObject(target) && isObject(source)) {
		for (const key in source) {
			if (isObject(source[key])) {
				if (!target[key]) Object.assign(target, { [key]: {} });
				mergeDeep(target[key], source[key]);
			} else {
				Object.assign(target, { [key]: source[key] });
			}
		}
	}

	return mergeDeep(target, ...sources);
}

/**
 * Create a text string in standard URL-encoded notation.
 * @param {object} obj
 * @return {string}
 */
export function serialize(obj) {
	return Object.keys(obj)
		.map((k) => `${encodeURIComponent(k)}=${encodeURIComponent(obj[k])}`)
		.join('&');
}

/**
 * Return an array of values that are included in both arrays.
 * @param {Array} array1
 * @param {Array} array2
 * @return {Array}
 */
export function intersect(array1, array2) {
	return array1.filter((value) => array2.includes(value));
}

/**
 * Check if given arrays share any values.
 * @param array1
 * @param array2
 * @return {boolean}
 */
export function shareAValue(array1, array2) {
	return intersect(array1, array2).length > 0;
}

/**
 * Check if the document dir is set to 'rtl'.
 * @return {boolean}
 */
export function isRTL() {
	return document.getElementsByTagName('html')[0].getAttribute('dir') === 'rtl';
}

/**
 * Return the document direction.
 * @return {string}
 */
export function getDir() {
	return isRTL() ? 'rtl' : 'ltr';
}

/**
 * Given a string which looks like time `13:00` parse it to date.
 * As this comes from time input the format is always 24 hrs.
 *
 * Alternatively use DayJS but because of custom format it requires
 * a parse plugin, which is 5Kb.
 *
 * @info {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/time#time_value_format}
 * @param {string} timeString - 24h format
 * @return {null|Date}
 */
export function parseTime(timeString) {
	if (!timeString) return null;

	const time = timeString.match(/(\d+)(:(\d\d))?\s*(p?)/i);
	if (time == null) return null;

	let hours = parseInt(time[1], 10);
	// If the time is 00 set to next day (24)
	if (hours === 0) {
		hours += 24;
	}
	const d = new Date();
	d.setHours(hours);
	d.setMinutes(parseInt(time[3], 10) || 0);
	d.setSeconds(0, 0);
	return d;
}

/**
 * Parse shift from-to string.
 * If the shift to (max) is below from (min) this means the shift extends to a new day.
 *
 * @param {String} shiftString Format `HH:mm-HH:mm`
 * @returns {{}|{min: (Date|null), max: (Date|null), minString: *, maxString: *}}
 */
export function parseShiftMinMax(shiftString) {
	const [minString, maxString] = (shiftString?.split('-') ?? []).map((s) => s.trim());

	if (!minString || !maxString) return {};

	const min = parseTime(minString);
	const max = parseTime(maxString);

	// Extend the max shift to new day
	if (max.getTime() < min.getTime()) {
		max.setDate(max.getDate() + 1);
	}

	return {
		min,
		max,
		minString,
		maxString,
	};
}

/**
 * Parse to and from (start, end) treatment times.
 * Both times are needed in case the `to` time is before `from`
 * that would mean it is a new day.
 *
 * @example
 * - Current date: 2022-03-23
 * parseToFromTime("09:15", "17:00")
 * => { from: "2022-03-23 09:15:00", to: "2022-03-23 17:00:00" } // Interpolated
 *
 * parseToFromTime("20:00", "01:15")
 * => { from: "2022-03-23 20:00:00", to: "2022-03-24 01:15:00" } // Interpolated
 *
 * @info {@link parseTime()}
 * @param {String} fromString
 * @param {String} toString
 * @returns {{from: (Date|null), to: (Date|null)}}
 */
export function parseToFromTime(fromString, toString) {
	const to = parseTime(toString);
	const from = parseTime(fromString);

	// Extend to the next day
	if (to?.getTime() < from?.getTime()) {
		to.setDate(to.getDate() + 1);
	}

	return { from, to };
}

/**
 * Generate random string that can be used as an id.
 * @returns {string}
 */
export function randomString() {
	return `_${Math.random().toString(36).substring(2, 9)}`;
}

/**
 * Split a path like string into an array.
 *
 * @example
 * 	stringToPath('b.c[0].d') // ['b', 'c', '0', 'd']
 * @param {String} path
 * @returns {*[]|*}
 */
function stringToPath(path) {
	// If the path isn't a string, return it
	if (typeof path !== 'string') return path;

	// Create new array
	const output = [];

	// Split to an array with dot notation
	path.split('.').forEach((item) => {
		// Split to an array with bracket notation
		item.split(/\[([^}]+)\]/g).forEach((key) => {
			// Push to the new array
			if (key.length > 0) {
				output.push(key);
			}
		});
	});

	return output;
}

/**
 * Return the object or array value for provided path.
 * @param object
 * @param {String|Array} path
 * @param fallback Default value if path not found
 * @returns {boolean|*}
 *
 * @example
 * const a = {
 *   b: {
 *     c: [
 *       {
 *         d: 'yey'
 *       }
 *     ]
 *   }
 * }
 * get(a, 'b.c[0].d)
 * //- 'yey'
 */
export function get(object, path, fallback = false) {
	const propertyPath = stringToPath(path);

	let current = object;
	for (let i = 0; i < propertyPath.length; i += 1) {
		if (!current[propertyPath[i]]) return fallback;
		current = current[propertyPath[i]];
	}

	return current;
}

/**
 * This is due to an old WebKit bug and implementation.
 *
 * @see {@link https://bugs.chromium.org/p/chromium/issues/detail?id=675795}
 * @see {@link https://medium.com/@owencm/one-weird-trick-to-performant-touch-response-animations-with-react-9fe4a0838116}
 * @see {@link https://www.youtube.com/watch?v=mmq-KVeO-uU&t=840s}
 * @param callback
 */
export function rAF(callback) {
	requestAnimationFrame(() => requestAnimationFrame(callback));
}
