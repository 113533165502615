/**
 * This fn will try to parse passed `value` and if error occurs
 * it will just return past `value`
 *
 * @param {string} value
 * @returns {any}
 */
export function safeParse(value) {
	try {
		return JSON.parse(value);
	} catch (e) {
		return value;
	}
}
