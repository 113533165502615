/* eslint-disable */
require('./bootstrap');
require('./disabled');

import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { http } from '../common/http';

import './tabs';
import { Dropdown } from '../common/components/Dropdown';
import { Modal } from '../common/Modal';

import { Lists } from '../common/components/Lists';
import VTextEditor from '../common/components/VTextEditor.vue';
import VTreatmentDaysAndShifts from '../common/components/booking/VTreatmentDaysAndShifts.vue';

// initialize Vue components
const vueWrapper = [...document.querySelectorAll('.vue-app-wrapper')];

if (vueWrapper.length) {

	vueWrapper.forEach((appEl) => {
		const store = createPinia();

		const app = createApp({})
		.component('v-text-editor', VTextEditor)
		.component('v-treatment-days-and-shifts', VTreatmentDaysAndShifts)
		.use(store);

		window.http = http;

		// Add window to Vue 3
		app.config.globalProperties.window = window;
		app.mount(appEl);
	});
}

Lists.init();
Dropdown.init();
Modal.init();

// Toggle narrow sidemenu
function classToggle() {
	var narrowSidemenu = document.querySelector('.side-menubar');
	var responsiveContent = document.querySelector('.content');

	narrowSidemenu.classList.toggle('responsive');
	responsiveContent.classList.toggle('responsive');
}

const menuToggleButton = document.querySelector('.button__toggle-sidemenu');
if (menuToggleButton) menuToggleButton.addEventListener('click', classToggle);

function responsiveMenuToggle() {
	var responsiveMenuButton = document.querySelector('.responsive-menu');
	responsiveMenuButton.classList.toggle('responsive');
}

const menuToggleResponsiveButton = document.querySelector('.button__toggle-responsive-menu');
menuToggleResponsiveButton.addEventListener('click', responsiveMenuToggle);

function openModal(modal) {
	modal.classList.add('is-open');
}

function closeModal(modal) {
	modal.classList.remove('is-open');
}

for (const element of document.querySelectorAll('[data-confirm]')) {
	const id = element.dataset.confirm;
	const modal = document.getElementById(`${id}-modal`);
	const form = modal.parentNode;
	const url = form.action;

	if (typeof modal == 'undefined') {
		continue;
	}

	modal.querySelectorAll('.close-modal-btn').forEach(function (button) {
		button.addEventListener('click', (event) => {
			event.preventDefault();
			closeModal(modal);
		});
	});

	if (form.classList.contains('ajax-form')) {
		form.addEventListener('submit', function (event) {
			event.preventDefault();

			for (const element of form.querySelectorAll('.ajax-message')) {
				if (element) {
					element.innerHTML = ' ';
				}
			}

			const formData = new FormData(form);
			axios
				.post(url, formData, {
					headers: {
						'Content-Type': 'multipart/form-data',
					},
				})
				.then((response) => {
					location.reload();
				})
				.catch(function (error) {
					var response = error.response.data.errors;
					var responseArray = Object.entries(response);

					for (const [key, value] of responseArray) {
						const parentEndDate = form.querySelector(`[name=${key}]`).parentNode;
						const spanError = form.querySelector(`[name=${key}]`).nextElementSibling;
						parentEndDate.classList.add('form-group--error');
						spanError.innerHTML += value;
					}
				});
		});
	}

	element.addEventListener('click', () => openModal(modal));
}

// Close modal on ESC
document.addEventListener('keydown', function (e) {
	let keyCode = e.keyCode;
	if (keyCode !== 27) {
		return;
	}

	document.querySelectorAll('.modal').forEach((modal) => closeModal(modal));
});

// Close flash message
var closeFlashMessageBtn = document.querySelector('.button__close--flash');
if (closeFlashMessageBtn) {
	closeFlashMessageBtn.addEventListener('click', function () {
		document.querySelector('.flash-message').style.display = 'none';
	});
}

window.readSelectedImage = function (input) {
	if (input.files && input.files[0]) {
		const wrapper = input.parentElement;

		// upload pdf logic 
		// doesn't change image upload since we don't show file name and this element is always hidden
		var filename = input.files[0].name;
		wrapper.querySelector('.upload-file__create').textContent = filename;

		let reader = new FileReader();
		reader.onload = function (e) {
			let newImage = new Image();
			newImage.src = e.target.result;

			newImage.onload = function () {
				wrapper.classList.add('upload-file--uploaded');

				const imagePreview = wrapper.querySelector('.upload-file__preview');
				imagePreview.style.backgroundImage = 'url(' + e.target.result + ')';
			};
		};

		reader.readAsDataURL(input.files[0]);
	}
};
