import { Litepicker } from 'litepicker';
import 'litepicker/dist/plugins/multiselect';
import MicroModal from 'micromodal';
import dayjs from 'dayjs';
import { DATE_FORMAT_API, DATE_HIDDEN_INPUT, LAPTOP_MEDIA_QUERY } from './utils/constants';
import { dateFormatForAPI } from './utils/dateFormatForAPI';
import { parseTemplateOptions } from './utils/pareTemplateOptions';

const RP_OPTION_PREFIX = 'rpOption';
const RP_BREAKPOINT_OPTION_PREFIX = 'rpBreakpoint';

const SELECTORS = {
	calendarMobile: 'open-calendar-mobile',
	onMobileInlineInModal: 'data-on-mobile-inline-in-modal',
};

/**
 *  Calendar class used for setting up custom options for `litepicker`
 *
 *  To define Calendar options in `template`(html)
 *  use attribute: `data-rp-option-<litepicker-option>`
 *  pass value: '<value>' or '{ "value": <value> }'
 *
 *
 *  e.g.
 *  How to set options `inline`, `minDate` & lockDays
 *
 *	data-rp-option-inline='true'
 *  data-rp-option-min-date='today'
 *  data-rp-option-lock-days='["2022-03-15", "2022-04-18"]'
 *
 *  data-rp-option-inline='{ "value": true }'
 *  data-rp-option-min-date='{ "value": "today" }'
 *  data-rp-option-lock-days='{ "value": ["2022-03-15", "2022-04-18"] }'
 *
 *	Breakpoint properties will be applied when the `this.mediaQuery` is met.
 *	All the properties will be reverted or deleted when the media query doesn't match.
 *  Custom options that are used for breakpoints have the following template:
 *
 *  data-rp-breakpoint-*
 *
 */
export class CalendarCustom {
	constructor(dateInput, customOptions = {}) {
		if (!dateInput) {
			throw Error('CalendarCustom not initialized');
		}

		const { dataset } = dateInput;
		const parseOptions = parseTemplateOptions({ dataset, optionPrefix: RP_OPTION_PREFIX });
		const parseBreakpoint = parseTemplateOptions({
			dataset,
			optionPrefix: RP_BREAKPOINT_OPTION_PREFIX,
		});
		const defaultOptions = {
			singleMode: false,
			numberOfColumns: 2,
			numberOfMonths: 2,
			showTooltip: false,
			switchingMonths: 1,
			dontAppendFromToInputs: false,
			setup: (instance) => {
				/**
				 * Because Litepicker doesn't emit any events on the actual element we can't add event listeners to the input.
				 * We need event listener on the dom element to be able to connect it to Vue (UpdateTimeframe.vue) or Vanilla JS
				 * without accessing the Litepicker instance.
				 *
				 * Therefore, we will emit `selected` event (`change` can't be emitted, infinite loop) and send the dates.
				 */
				instance.on('selected', (...dates) => {
					CalendarCustom.emitEventSelected({ instance, dates });
				});
			},
		};

		const customUserFormat = {
			parse(date) {
				return CalendarCustom.parseDate(date);
			},
			output(date) {
				return CalendarCustom.formatDate({ date, options: parseOptions });
			},
		};

		this.options = {
			...defaultOptions,
			...customOptions,
			...parseOptions,
			...(parseOptions.format || { format: customUserFormat }),
			breakpoints: parseBreakpoint,
		};
		this.dateInput = dateInput;
		this.mediaQuery = window.matchMedia(LAPTOP_MEDIA_QUERY);
		this.parseOptions = parseOptions;
		this.backupSettings = {};

		this.setup();
	}

	/**
	 * Initialize a calendar with custom config options:
	 *  - options: @see {@link https://litepicker.com/docs/options}
	 * 	- format: Will behave like in litepicker documentation // But I recommend using `userFormat` since it would not have side effects on format for options...
	 *
	 *  CalendarCustom Additional Options:
	 *
	 *  - userFormat: use `userFormat` if you want to change format visible for User
	 *  							and not change format for other options `DATE_FORMAT_API` (YYYY-MM-DD)
	 *  							if `userFormat` is not defined, it will use `lang` to set date format
	 *  							if `lang`	is not defined, it will use  `DATE_FORMAT_API` (YYYY-MM-DD)
	 *  - dontAppendFromToInputs:
	 *  							if this is `true` => CalendarCustom will not append `from` & `to` hidden inputs.
	 * 								if this is `false => CalendarCustom will append `from` & `to` inputs for non-single calendars
	 *  								and will keep them in sync with range value if `selected` event is emitted.
	 *  							default value: `false`
	 */
	initLitepicker() {
		this.instance = new Litepicker({
			element: this.dateInput,
			...this.options,
		});

		this.addHelperFunctions();
	}

	/**
	 * Reinitialize the litepicker instance but remember the selected dates.
	 * Note: It kind of works with multiple select plugin, but it is kind of buggy.
	 * At the moment there isn't an easy way to set multiple dates.
	 */
	memoizeInitLitePicker() {
		let startDate;
		let endDate;
		let dates;

		// Remember the date before destroying the instance.
		if (this.isMultipleSelect()) {
			dates = this.instance.getMultipleDates();
		} else {
			[startDate, endDate] = [this.instance.getStartDate(), this.instance.getEndDate()];
		}
		this.instance.destroy();
		this.initLitepicker();

		// Apply the memorized dates.
		if (this.isMultipleSelect() && dates?.length) {
			this.instance.selectMultipleDates(dates);
			return;
		}

		if (startDate) this.instance.setStartDate(startDate);
		if (endDate) this.instance.setEndDate(endDate);
	}

	/**
	 * Appends Input to parent element.
	 * It is convenient for making hidden inputs `from` & `to` for range picker.
	 *
	 * @param {HTMLElement} parent
	 * @param {string} name
	 * @param {string} type - by default: `hidden`
	 */
	static appendInput({ parent, name, type }) {
		const input = document.createElement('input');
		input.name = name;
		input.type = type;
		parent.appendChild(input);
		return input;
	}

	/**
	 * Appends `from` & `to` hidden inputs to parent element & returns them as array `[inputFrom, inputTo]`;
	 *
	 * @param parent
	 * @return {HTMLInputElement[]}
	 */
	static createFromToInputs({ parent }) {
		const type = 'hidden';
		const inputFrom = CalendarCustom.appendInput({
			parent,
			name: 'from',
			type,
		});
		const inputTo = CalendarCustom.appendInput({
			parent,
			name: 'to',
			type,
		});

		return [inputFrom, inputTo];
	}

	/**
	 * Returns `date string` in specific format based on `options`
	 *
	 * @param date
	 * @param options
	 * @return {string}
	 */
	static formatDate({ date, options }) {
		const { userFormat, lang } = options;

		let dateStr;

		if (userFormat) {
			dateStr = dayjs(date).format(userFormat);
		} else if (lang) {
			dateStr = date.toLocaleDateString(lang);
		} else {
			dateStr = dayjs(date).format(DATE_FORMAT_API);
		}

		return dateStr;
	}

	/**
	 * Parses date object or string to `new Date` object
	 *
	 * @param date
	 * @return {Date}
	 */
	static parseDate(date) {
		if (date instanceof Date) {
			return date;
		}

		if (typeof date === 'string') {
			return new Date(dayjs(date, DATE_FORMAT_API));
		}

		// from unix timestamp (eg.: new Date().getTime())
		if (typeof date === 'number') {
			return new Date(date);
		}

		return new Date();
	}

	onInputClick() {
		if (this.isMobile()) MicroModal.show(SELECTORS.calendarMobile);
	}

	hasInlineModalOption() {
		return this.dateInput.hasAttribute(SELECTORS.onMobileInlineInModal);
	}

	/**
	 * Check if the calendar has breakpoint settings.
	 * @returns {boolean}
	 */
	hasBreakpointSetting() {
		return !!Object.keys(this.options.breakpoints).length;
	}

	createBackupSettings() {
		/**
		 * If there is a flag for inline modal force the inline mode on mobile
		 * and add it explicitly to backupSettings.
		 * That is a requirement for modal and resize to work correctly.
		 */
		if (this.hasInlineModalOption()) {
			this.options.breakpoints.inlineMode = true;
			this.backupSettings.inlineMode = this.options.inlineMode;
		}

		if (!this.hasBreakpointSetting()) return;

		Object.keys(this.options.breakpoints).forEach((key) => {
			this.backupSettings[key] = this.options[key];
		});
	}

	revertMobileSettings() {
		let value;
		Object.keys(this.backupSettings).forEach((key) => {
			value = this.backupSettings[key];
			if (value === null || value === undefined) {
				delete this.options[key];
			} else {
				this.options[key] = value;
			}
		});
	}

	/**
	 * Apply settings for smaller devices.
	 */

	applyMobileSettings() {
		this.options = { ...this.options, ...this.options.breakpoints };
	}

	/**
	 * Emits `selected` event with `detail: { from , to }`.
	 * When this event is emitted hidden inputs `from` & `to` are updated.
	 *
	 * @param instance
	 * @param dates
	 */
	static emitEventSelected({ instance, dates }) {
		const [from, to] = dates;
		const element = instance?.options?.element;
		const singleMode = instance?.options?.singleMode;
		if (!element) return;
		/** @info {@link https://developer.mozilla.org/en-US/docs/Web/API/CustomEvent/CustomEvent} */
		const event = new CustomEvent('selected', {
			detail: { from, to, singleMode },
		});
		element?.dispatchEvent(event);
	}

	/**
	 * Bind `this` explicitly for event listeners.
	 */
	bindEvents() {
		['updateFromToInputs', 'updateHiddenInput', 'onMediaQueryChange', 'onInputClick'].forEach(
			(e) => {
				this[e] = this[e].bind(this);
			}
		);
	}

	/**
	 * Is mobile media query met.
	 * @return {boolean}
	 */
	isMobile() {
		return this.mediaQuery.matches;
	}

	/**
	 * Check if the breakpoint has a parent element specified.
	 * Because a parent element can be updated, the calendar needs
	 * to be destroyed and created from the start.
	 *
	 * @returns {boolean}
	 */
	shouldUpdateParentEl() {
		return !!this.options.breakpoints?.parentEl;
	}

	/**
	 * When the media query changes to desktop remove all mobile classes.
	 * @param {MediaQueryListEvent} e
	 */
	onMediaQueryChange(e) {
		if (!this.hasBreakpointSetting()) return;

		// Check media query
		if (e.matches) this.applyMobileSettings();
		else this.revertMobileSettings();

		// Update the instance
		this.memoizeInitLitePicker();
	}

	/**
	 * Sets single or range mode & adds proper inputs for that mode.
	 * Set range mode by setting `single` to `false`.
	 *
	 * @param {boolean} single
	 */
	setMode({ single }) {
		this.instance.setOptions({
			singleMode: single,
		});
		if (single) {
			this.removeFromToInputs();
			this.appendDateInput();
		} else {
			this.removeDateInput();
			this.appendFromToInputs();
		}
	}

	/**
	 * Add hidden `from` & `to` inputs witch represent selected dates.
	 * This is used for range mode.
	 */
	appendFromToInputs() {
		[this.inputFrom, this.inputTo] = CalendarCustom.createFromToInputs({
			parent: this.dateInput.parentElement,
		});

		this.dateInput.addEventListener('selected', this.updateFromToInputs);
	}

	/**
	 * Add hidden input witch represent selected date.
	 * This is used for single mode.
	 */
	appendDateInput() {
		const inputName = this.parseOptions.inputName || DATE_HIDDEN_INPUT;
		this.hiddenInput = CalendarCustom.appendInput({
			parent: this.dateInput.parentElement,
			name: inputName,
			type: 'hidden',
		});

		this.dateInput.addEventListener('selected', this.updateHiddenInput);
	}

	removeFromToInputs() {
		this.inputFrom.remove();
		this.inputTo.remove();
		this.dateInput.removeEventListener('selected', this.updateFromToInputs);
	}

	removeDateInput() {
		this.hiddenInput.remove();
		this.dateInput.removeEventListener('selected', this.updateHiddenInput);
	}

	/**
	 * Sets value for `from` & `to` inputs with proper date format for BE
	 *
	 * @param event
	 */
	updateFromToInputs(event) {
		let { from, to } = event.detail;
		from = from.dateInstance;
		to = to.dateInstance;

		this.inputFrom.value = dateFormatForAPI(from);
		this.inputTo.value = dateFormatForAPI(to);
	}

	/**
	 * Set the value for the hidden input in the `singleMode`.
	 *
	 * @param event
	 */
	updateHiddenInput(event) {
		const { from } = event.detail;
		this.hiddenInput.value = dateFormatForAPI(from.dateInstance);
	}

	/**
	 * Check if the Litepicker instance has multiple select plugin initialized.
	 * @see {@link https://litepicker.com/docs/plugins/multiselect/}
	 * @returns {boolean}
	 */
	isMultipleSelect() {
		return this.instance.options.plugins?.some((plugin) => plugin === 'multiselect') ?? false;
	}

	/**
	 * Adds to `instance` when `multiselect` plugin on:
	 * `unselectDate`
	 * `selectMultipleDates`
	 *
	 * Example of usage:
	 *
	 * `this.instance.unselectDate(new Date());`
	 * `this.instance.selectMultipleDates([new Date()], {events: [multiselect.multi.select]})`
	 */
	addHelperFunctions() {
		if (this.isMultipleSelect()) {
			/**
			 * Unselect date from calendar.
			 *
			 * @param {Date | string} date
			 * @param triggerDeselectEvent
			 */
			this.instance.unselectDate = (date, { triggerDeselectEvent = true }) => {
				const time = dayjs(date).valueOf();

				this.instance.preMultipleDates = this.instance.preMultipleDates.filter(
					(mTime) => mTime !== time
				);

				if (triggerDeselectEvent) {
					this.instance.emit('multiselect.deselect', this.instance.DateTime(time));
				}
				this.instance.render();
			};

			/**
			 * Selects multiple dates and
			 * Emits every event with payload
			 *
			 * @param {Array<Date | string | number>} dates
			 * @param {Array<string>} events
			 */
			this.instance.selectMultipleDates = (dates, { events = [] } = {}) => {
				const times = dates.map((date) => dayjs(date).valueOf());

				this.instance.preMultipleDates = [...this.instance.preMultipleDates, ...times];

				const payload = times.map((time) => this.instance.DateTime(time));
				events.forEach((event) => {
					this.instance.emit(event, payload);
				});

				this.instance.render();
			};
		} else {
			/**
			 * Not implemented & will throw an error
			 */
			this.instance.unselectDate = () => {
				throw new Error('`unselectDate` fn is implemented only for `multiselect` plugin');
			};

			/**
			 * Not implemented & will throw an error
			 */
			this.instance.selectMultipleDates = () => {
				throw new Error('`selectMultipleDates` fn is implemented only for `multiselect` plugin');
			};
		}
	}

	setupEventListeners() {
		// Disable the user from typing into calendar input
		this.dateInput.addEventListener('keypress', (e) => e.preventDefault());
		this.mediaQuery.addEventListener('change', this.onMediaQueryChange);
	}

	setup() {
		this.bindEvents();
		this.setupEventListeners();
		this.createBackupSettings();

		/**
		 * When `onMobileInlineInModal` attribute is present on the calendar it means
		 * that the calendar should open in a modal on a smaller device.
		 */
		if (this.hasInlineModalOption()) {
			this.dateInput.addEventListener('click', this.onInputClick);
		}

		if (this.isMobile()) {
			this.applyMobileSettings();
		}

		this.initLitepicker();

		if (!this.options.singleMode && !this.options.dontAppendFromToInputs) {
			this.appendFromToInputs();
		}

		if (this.options.singleMode) {
			this.appendDateInput();
		}

		/*
			If there are start and end date pre-defined emit the initial select.
			This will set to and from and trigger any selected events.
		*/
		const dates = [this.instance.getStartDate(), this.instance.getEndDate()];
		if (dates.some((d) => Boolean(d))) {
			CalendarCustom.emitEventSelected({
				instance: this.instance,
				dates,
			});
		}
	}
}
