import { unCapitalize } from './unCapitalize';
import { safeParse } from './safeParse';

/**
 * Parses options from a template so they can be used for the configuration of custom components.
 *
 * @param dataset
 * @param {string} optionPrefix
 *
 * @return {{}}
 */
export function parseTemplateOptions({ dataset, optionPrefix }) {
	let optKey;
	let optValue;
	return Object.entries(dataset)
		.filter(([key]) => key.includes(optionPrefix))
		.map(([key, value]) => {
			optKey = key.replace(optionPrefix, '');
			optKey = unCapitalize(optKey);
			optValue = safeParse(value);
			optValue = optValue.value ?? optValue;

			return { [optKey]: optValue };
		})
		.reduce((pVal, cVal) => ({ ...pVal, ...cVal }), {});
}
