import { computePosition, flip, offset } from '@floating-ui/dom';
import { SelectorEngine } from '../../platform/utils/SelectorEngine';

/** @see {@link https://floating-ui.com/} */
const DEFAULT_POSITION = 'bottom-start';

const CLASSES = {
	active: 'active',
};

const SELECTORS = {
	root: '.dropdown',
	trigger: '[data-toggle="dropdown"]:not([class*="bookdialysis-search-results-dropdown"])',
	menu: '.dropdown-menu',
	item: '.dropdown__item',
	indicator: '.dropdown__indicator',
	close: '[data-dropdown-close]',
};

export class Dropdown {
	constructor(element) {
		this.element = element; // [data-trigger="dropdown"]

		this.bindEvents();
		this.setup();
	}

	static init() {
		/**
		 * Initialize for all dropdowns
		 */
		const dropdowns = Array.from(document.querySelectorAll(SELECTORS.trigger)) || [];

		if (dropdowns.length > 0) {
			dropdowns.forEach((dropdown) => new Dropdown(dropdown));
		}
	}

	/**
	 * Bind `this` explicitly for event listeners.
	 */
	bindEvents() {
		['handleTriggerClick', 'handleClick'].forEach((e) => {
			this[e] = this[e].bind(this);
		});
	}

	toggleIndicator() {
		if (!this.indicatorElement) return;

		this.indicatorElement.classList.toggle(CLASSES.active);
	}

	setPosition() {
		computePosition(this.element, this.menuElement, {
			placement: this.menuPosition,
			middleware: [offset(5), flip()],
		}).then(({ x, y }) => {
			Object.assign(this.menuElement.style, {
				left: `${x}px`,
				top: `${y}px`,
			});
		});
	}

	show() {
		this.element.focus();
		this.element.setAttribute('aria-expanded', true);
		this.element.classList.add(CLASSES.active);
		this.menuElement.classList.add(CLASSES.active);
		if (this.rootElement) this.rootElement.classList.add(CLASSES.active);
		this.setPosition();
		this.toggleIndicator();
	}

	hide() {
		this.element.setAttribute('aria-expanded', false);
		this.element.classList.remove(CLASSES.active);
		this.menuElement.classList.remove(CLASSES.active);
		if (this.rootElement) this.rootElement.classList.remove(CLASSES.active);
		this.toggleIndicator();
	}

	toggle() {
		return this.isActive() ? this.hide() : this.show();
	}

	isActive() {
		return this.element.classList.contains(CLASSES.active);
	}

	getMenuElement() {
		return SelectorEngine.next(this.element, SELECTORS.menu)[0];
	}

	getRootElement() {
		return this.element.closest(SELECTORS.root);
	}

	getIndicatorElement() {
		return this.rootElement?.querySelector(SELECTORS.indicator);
	}

	handleTriggerClick(e) {
		e.preventDefault(); // Possible to click on links
		this.toggle();
	}

	getMenuPosition() {
		return this.element.dataset.position || DEFAULT_POSITION;
	}

	/**
	 * When there is a click on the body determine if the dropdown should be closed.
	 * It closes in the following cases:
	 * 	1. If the clicked element is a `SELECTORS.item`.
	 * 	2. If the clicked element is a `SELECTORS.close`.
	 * 	3. If the click is outside the menu or trigger elements.
	 * @param {Event} e
	 */
	handleClick(e) {
		if (
			e.target.closest(SELECTORS.item) ||
			e.target.closest(SELECTORS.close) ||
			(!this.element.contains(e.target) && !this.menuElement.contains(e.target) && this.isActive())
		) {
			this.hide();
		}
	}

	setupEventListeners() {
		this.element.addEventListener('click', this.handleTriggerClick);
		document.addEventListener('click', this.handleClick);
	}

	setup() {
		this.menuElement = this.getMenuElement();

		if (!this.element || !this.menuElement) return;

		this.rootElement = this.getRootElement();
		this.indicatorElement = this.getIndicatorElement();
		this.menuPosition = this.getMenuPosition();

		this.setupEventListeners();
	}
}
