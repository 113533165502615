/**
 * Returns new instance of passed value.
 * NOTE: This will not copy function if there are any in object.
 *
 * @param value
 * @returns {any}
 */
export function clone(value) {
	return JSON.parse(JSON.stringify(value));
}
