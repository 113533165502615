import { defineStore } from 'pinia';
import { get } from '../../utils/utils';

export default defineStore('offlineBooking', {
	state: () => ({
		template: '',
		patient: null,
		treatmentDate: null,
		// Treatment type used for weekly booking.
		treatmentType: null,
		weeklyBookingDetailsValid: false,
		documentation: 'missing',
		slot: {},
		treatment: {
			shift: null,
			start: null,
			end: null,
			valid: false,
		},
		errors: window.errors || {},
	}),
	getters: {
		isSingleTreatment(state) {
			return state.template === 'single';
		},
		isWeeklyTreatment(state) {
			return state.template === 'weekly';
		},
		isFormValid(state) {
			if (this.isSingleTreatment) {
				return !!state.treatmentDate && state.treatment.valid && !!state.patient;
			}

			if (this.isWeeklyTreatment) {
				return !!state.treatmentDate && !!state.patient && state.weeklyBookingDetailsValid;
			}
			return false;
		},
		getError(state) {
			// Object destruct is needed to unwrap the proxy
			// There was a problem if `path` would be a native Proxy function, like shift
			return (path) => get({ ...state.errors }, path, false);
		},
	},
	actions: {
		changeTemplate(tmpl) {
			this.template = tmpl;
		},
	},
});
