/*
 * Not actual cache, but a hack to fix flaky `svg-sprite-loader` not generating the file.
 * Assigning the value to "something" fixes the problem as Webpack resolves it.
 */
const cache = {};

function importAll(r) {
	// eslint-disable-next-line no-return-assign
	r.keys().forEach((key) => (cache[key] = r(key)));
}

importAll(require.context('../../icons', false, /\.svg$/));
