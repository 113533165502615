export const SelectorEngine = {
	isElement(element) {
		return element instanceof Element || element instanceof HTMLDocument;
	},

	getElement(element) {
		if (this.isElement(element)) return element;

		if (typeof element === 'string' && element.length > 0) {
			return document.querySelector(element);
		}

		return null;
	},

	/**
	 * Return a **previous** sibling element that matches a selector.
	 * @param {HTMLElement} element
	 * @param {String} selector
	 * @return {Element[]|*[]}
	 */
	prev(element, selector) {
		let previous = element.previousElementSibling;

		while (previous) {
			if (previous.matches(selector)) {
				return [previous];
			}

			previous = previous.previousElementSibling;
		}

		return [];
	},

	/**
	 * Return a **next** sibling element that matches a selector.
	 * @param {HTMLElement} element
	 * @param {String} selector
	 * @return {Element[]|*[]}
	 */
	next(element, selector) {
		if (!this.isElement(element)) return [];

		let next = element.nextElementSibling;

		while (next) {
			if (next.matches(selector)) {
				return [next];
			}

			next = next.nextElementSibling;
		}

		return [];
	},

	/**
	 * Return siblings that match the provided selector.
	 *
	 * @param {HTMLElement} element
	 * @param {String} selector
	 * @returns {Element[]|*[]|null}
	 */
	siblings(element, selector) {
		// if no parent, return no sibling
		if (!element.parentNode) {
			return [];
		}

		// Get the first child and use the next function
		const sibling = element.parentNode.firstChild;
		return this.next(sibling, selector);
	},

	/**
	 * Get a single sibling element.
	 *
	 * @param {HTMLElement} element
	 * @param {String} selector
	 * @returns {HTMLElement|null}
	 */
	sibling(element, selector) {
		return this.siblings(element, selector)?.[0] ?? null;
	},
};
