import MicroModal from 'micromodal';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { onShow } from './components/utils/modalTemplateEngine';
import { ClinicGallerySlider } from '../platform/components/clinic/ClinicGallerySlider';

/**
 * Wrapper for MicroModal.
 *
 * [1]: onShow returns a `trigger` argument that doesn't work on Safari.
 *			Due to the use of activeElement it will return `document.body` for most cases.
 *			Therefore, we extract the trigger based on event target.
 *
 * @see {@link https://github.com/ghosh/Micromodal/blob/2a316150d12b3f3a0540762ee77dcb7247c6518a/lib/src/index.js#L59}
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Document/activeElement}
 */
export class Modal {
	static init() {
		MicroModal.init({
			disableFocus: true,
			onShow: (modal, _, e) => {
				const trigger = e.target; // [1]
				onShow(modal, trigger, () => Modal.onModalShown(modal, trigger));
			},
			onClose: (modal) => Modal.enableModalScroll(modal),
		});

		document.querySelectorAll('.modal[data-popup]').forEach((modal) => {
			const popupLS = { key: `popup-${modal.id}`, value: 'dismissed' };

			const isDismissed = localStorage.getItem(popupLS.key) === popupLS.value;
			if (isDismissed) return;

			MicroModal.show(modal.id, {
				onClose: () => localStorage.setItem(popupLS.key, popupLS.value),
			});
		});
	}

	static onModalShown(modal, trigger) {
		Modal.disabledModalScroll(modal);
		ClinicGallerySlider.onModalOpen(modal, trigger);
	}

	static enableModalScroll(modal) {
		enableBodyScroll(modal.querySelector('.modal__container'));
	}

	static disabledModalScroll(modal) {
		disableBodyScroll(modal.querySelector('.modal__container'));
	}
}
