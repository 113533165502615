const ATTRIBUTES = {
	template: 'data-list-template',
	items: 'data-list-items',
	item: 'data-list-item',
	remove: 'data-list-remove',
	trigger: 'data-list-trigger',
};

const SELECTORS = {
	template: `[${ATTRIBUTES.template}]`,
	items: `[${ATTRIBUTES.items}]`,
	item: `[${ATTRIBUTES.item}]`,
	remove: `[${ATTRIBUTES.remove}]`,
	trigger: `[${ATTRIBUTES.trigger}]`,
};

export class List {
	constructor(element) {
		this.listElement = element;
		this.templateElement = this._getElement(SELECTORS.template, this.listElement);
		this.itemsElement = this._getElement(SELECTORS.items, this.listElement);
		this.triggerElement = this._getElement(SELECTORS.trigger, this.listElement);

		this._bindEventListener();
		this.setup();
	}

	/**
	 * Explicitly bind `this` for events.
	 * @private
	 */
	_bindEventListener() {
		['onAddNew', 'onListClickDelegation'].forEach((e) => {
			this[e] = this[e].bind(this);
		});
	}

	/**
	 * Utility function that will return the element if it exists.
	 * If the element is not found it will throw a verbose error.
	 *
	 * @param {string} selector
	 * @param {Element} parent
	 * @return {Element} Queried element
	 * @private
	 */
	static _getElements(selector, parent = document) {
		const element = parent.querySelectorAll(selector);

		if (!element) throw new Error(`Structure is wrong. Missing "${selector}" element.`);

		return element;
	}

	/**
	 * @see _getElements
	 * @param {string} selector
	 * @param {Element} parent
	 * @return {Element}
	 * @private
	 */
	_getElement(selector, parent = document) {
		return this.constructor._getElements(selector, parent)[0];
	}

	/**
	 * Clone the template and remove it from DOM so it is not sent on submit.
	 */
	initTemplate() {
		/**
		 * Remove template from DOM, if it's part of a form we don't want to send it.
		 *
		 * Note: Note supported in IE
		 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/remove}
		 */
		this.templateElement.remove();
		this.templateElement = this.templateElement.cloneNode(true);
		// Remove template attribute
		this.templateElement.removeAttribute(ATTRIBUTES.template);
		this.templateElement.removeAttribute('style');
		// Add attribute for single item
		this.templateElement.setAttribute(ATTRIBUTES.item, '');
	}

	/**
	 * If the template contains inputs and labels, and they are linked with `for` and `id`
	 * they won't work. Furthermore, a duplicate ID will be created on the page.
	 *
	 * This function will find all labels with `for` attribute and find the right input,
	 * then generate a unique ID and replace it on both elements.
	 *
	 * @param {Element} element
	 * @private
	 */
	static fixIds(element) {
		// Find all inputs with for attribute
		const elementsWithFor = element.querySelectorAll('label[for]');
		if (elementsWithFor) {
			Array.from(elementsWithFor).forEach((label) => {
				const forAttribute = label.htmlFor;
				// If `for` attribute is empty, exit.
				if (!forAttribute) return;
				// Generate new ID based on time and random number.
				const newId = `id-${new Date().getTime() + Math.random().toString(36).slice(2)}`;
				// Find the input with the given ID.
				const input = element.querySelector(`#${forAttribute}`);

				if (input) {
					// Replace the `id` attribute
					input.id = newId;
					// Replace the `for` attribute
					label.htmlFor = newId;
				}
			});
		}
	}

	/**
	 * In case that element consists of two form elements, they need to belong to the same array key
	 * in order for backend to parse them properly. This function generates unique array key in that case.
	 *
	 * @param {Element} element
	 * @private
	 */
	static fixNames(element) {
		const formElements = element.querySelectorAll('input, select, textarea');

		if (formElements.length > 1) {
			const newKey = `key-${new Date().getTime() + Math.random().toString(36).slice(2)}`;

			Array.from(formElements).forEach((input) => {
				input.name = input.name.replace(`[]`, `[${newKey}]`);
			});
		}
	}

	createNewItem() {
		const templateElement = this.templateElement.cloneNode(true);

		this.constructor.fixIds(templateElement);
		this.constructor.fixNames(templateElement);

		return templateElement;
	}

	/**
	 * Remove the given element.
	 * @param {Element} element
	 */
	static removeItem(element) {
		element.remove();
	}

	/**
	 * Add new item from template
	 */
	onAddNew(e) {
		e.preventDefault();
		this.itemsElement.appendChild(this.createNewItem());
	}

	/**
	 * When someone clicks on the list.
	 * Event delegation pattern.
	 *
	 * @param {Event} e
	 */
	onListClickDelegation(e) {
		e.preventDefault();

		/** @var {Element} */
		const element = e.target;

		if (!element || !element.matches(SELECTORS.remove)) return;

		/**
		 * Find the item parent.
		 *
		 * Note: Note supported in IE
		 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Element/closest}
		 * @see {@link https://caniuse.com/element-closest}
		 */
		const parent = element.closest(SELECTORS.item);
		this.constructor.removeItem(parent);
	}

	addEventListeners() {
		this.triggerElement.addEventListener('click', this.onAddNew);
		this.listElement.addEventListener('click', this.onListClickDelegation);
	}

	/**
	 * Call on destroy to remove events.
	 */
	destroy() {
		this.triggerElement.removeEventListener('click', this.onAddNew);
		this.listElement.removeEventListener('click', this.onListClickDelegation);
	}

	setup() {
		this.initTemplate();
		this.addEventListeners();
	}
}
