import dayjs from 'dayjs';
import { isFunction, isString } from './utils';

export function dateGetMonthRange({ date = new Date(), format }) {
	let from = dayjs(date).startOf('M');
	let to = dayjs(date).endOf('M');

	if (isFunction(format)) {
		from = format(from);
		to = format(to);
	}

	if (isString(format)) {
		from = from.format(format);
		to = to.format(format);
	}

	return { from, to };
}
