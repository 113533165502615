/**
 * Used for templating engines.
 * If the value inside the data is an object we need a way to set the text.
 * This property will be used to set the element content.
 *
 * @see modalTemplateEngine.js
 * @see BookingCard.js
 * @type {string}
 */
export const DATA_TEXT_PROPERTY = 'text';

export const SMALL_MEDIA_QUERY = '(max-width: 640px)';
export const MOBILE_MEDIA_QUERY = '(max-width: 768px)';
export const LAPTOP_MEDIA_QUERY = '(max-width: 1023px)';

export const DATE_FORMAT_API = 'YYYY-MM-DD';
/**
 * Default hidden input name in `singleMode` for calendar.
 * @see {CalendarCustom.js}
 * @type {string}
 */
export const DATE_HIDDEN_INPUT = 'date';

export const FILE_ACCEPT_DEFAULT = ['.jpg', '.png,', '.pdf', '.doc', '.docx'];

/**
 * Map BE days of week keys to JS days of week.
 * `0 (Sunday) to 6 (Saturday)`
 *
 * @see {@link https://day.js.org/docs/en/get-set/day}
 * @type {string[]}
 */
export const BE_DAYS_OF_WEEK = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

/**
 * Regex to check for UUID v4.
 * Used inside the HTTP service to check if the key is a UUID.
 */
export const UUIDV4RegEx =
	/^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

/**
 * Dialysis types that can be provided from BE.
 * Used while updating timeslots.
 * @see {UpdateTimeframe.vue}
 * @type {{HDF: string, HD: string}}
 */
export const DialysisType = {
	HD: 'hd',
	HDF: 'hdf',
};
