import { camelCase, paramCase } from 'change-case';

/**
 * This file was separated from modalTemplateEngine to prevent cycle dependency.
 * @see {modalTemplateEngine.js}
 */

/**
 * The **data** attribute that is used as a template.
 * It needs to be a data attribute.
 */
export const TEMPLATE_ATTRIBUTE = 'data-template';

/**
 * Pre-computed value.
 * Because `element.dataset` returns all data attributes without `data-` prefix
 * and all attributes are camel cased we need to transform our initial template.
 *
 * @example
 * <div data-template-test />
 * // => { templateTest }
 *
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/dataset}
 * @see {isTemplateAttribute}
 * @private
 */
export const _TEMPLATE_ATTRIBUTE_PREFIX = camelCase(TEMPLATE_ATTRIBUTE.replace('data-', ''));

/**
 * Check if the given attribute matches the `TEMPLATE_ATTRIBUTE`.
 *
 * @param {string} attribute
 * @return {boolean}
 */
export function isTemplateAttribute(attribute) {
	const attributeRegex = RegExp(`^${_TEMPLATE_ATTRIBUTE_PREFIX}[A-Z](.*)`);

	return attributeRegex.test(attribute);
}

/**
 * Check if the given attribute in param(slug) case is a template attribute
 * @example
 * hasTemplateAttributePrefix('data-template-example');
 * // returns true
 * @example
 * hasTemplateAttributePrefix('data-example-template');
 * // returns false
 * @param attribute
 * @return {boolean}
 */
export function hasTemplateAttributePrefix(attribute) {
	const attributeRegex = RegExp(`^${TEMPLATE_ATTRIBUTE}-[a-z](.*)`);
	return attributeRegex.test(attribute);
}

/**
 * Transform any key from data set object to data attribute selector.
 *
 * Note: paramCase === kebabCase
 *
 * @example toDataSelector('fooBar') // => 'data-foo-bar'
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/dataset#name_conversion}
 * @param {string} dataKey
 * @param {string} dataKey
 */
export function toDataSelector(dataKey) {
	return `[data-${paramCase(dataKey)}]`;
}
