import { List } from './List';

const ATTRIBUTES = {
	root: 'data-list',
};

const SELECTORS = {
	root: `[${ATTRIBUTES.root}]`,
};

export class Lists {
	constructor() {
		// Find all list
		this.listElements = document.querySelectorAll(SELECTORS.root);
		// Exit if no lists are found
		if (!this.listElements) return;
		Array.from(this.listElements).forEach((e) => new List(e));
	}

	static init() {
		// eslint-disable-next-line no-new
		new Lists();
	}
}
