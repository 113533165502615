import dayjs from 'dayjs';
import { dateFormatForAPI } from '../utils/dateFormatForAPI';

export class ShiftModel {
	constructor({ date, selected = '', options, error = '' }) {
		this.date = dayjs(date);
		this.selected = selected;
		this.options = options;
		this.error = error;
		this.dateAPI = dateFormatForAPI(date);
	}
}
