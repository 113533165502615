import axios from 'axios';
import applyCaseMiddleware from 'axios-case-converter';
import { UUIDV4RegEx } from './components/utils/constants';

const options = {
	preservedKeys(input) {
		/**
		 * Check if the keys are an UUID string, if that is true don't
		 * transform the keys. Transforming the UUID keys will change and break them.
		 */
		return UUIDV4RegEx.test(input);
	},
};

export const http = applyCaseMiddleware(
	axios.create({
		headers: {
			'Content-Type': 'application/json',
		},
	}),
	options
);
